import "@hotwired/turbo-rails";

import {
  onBeforeCache,
  onBeforeFrameRender,
  onBeforeRender,
} from "@/vendor/turbo";

// tooltip, popover, modal をキャッシュしない
onBeforeCache(() => {
  document
    .querySelectorAll<HTMLElement>(
      ".tooltip, .popover, .modal.show, .modal.in, .modal-backdrop"
    )
    .forEach((e) => {
      e.dataset.turboTemporary = "";
    });
  // modal は閉じていることにする
  document.body.classList.remove("modal-open");
});

// data-turbo-scroll-preserve attribute をつけると横スクロールを維持する
function scrollCacheRender(defaultRender) {
  return (currentBody: any, newBody: any) => {
    const leftCaches: Record<string, number> = {};
    const topCaches: Record<string, number> = {};
    currentBody
      .querySelectorAll("[data-turbo-scroll-preserve][id]")
      .forEach((el) => {
        leftCaches[el.id] = el.scrollLeft;
      });
    currentBody
      .querySelectorAll("[data-turbo-scrolltop-preserve][id]")
      .forEach((el) => {
        topCaches[el.id] = el.scrollTop;
      });

    defaultRender(currentBody, newBody);
    document.body
      .querySelectorAll("[data-turbo-scroll-preserve][id]")
      .forEach((el) => {
        if (leftCaches[el.id]) {
          el.scrollLeft = leftCaches[el.id];
        }
      });
    document.body
      .querySelectorAll("[data-turbo-scrolltop-preserve][id]")
      .forEach((el) => {
        if (topCaches[el.id]) {
          el.scrollTop = topCaches[el.id];
        }
      });
  };
}

onBeforeRender((event) => {
  event.detail.render = scrollCacheRender(event.detail.render);
});

onBeforeFrameRender((event) => {
  event.detail.render = scrollCacheRender(event.detail.render);
});
